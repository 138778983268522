import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";


const AlertBanner = ({message, linkURL, linkLabel, updatedAt}) => {
  const isSSR = typeof window === "undefined"
  const [ visible, setVisible ] = useState(false)

  useEffect(() => {
    if(!isSSR){
      if(sessionStorage.getItem("alert-hide") == "yes") setVisible(false)
      else setVisible(true)
    }
  }, [])

  useEffect(() => {
    if(!isSSR){
      if(visible) sessionStorage.clear("alert-hide")
      else sessionStorage.setItem("alert-hide", "yes")
    }
  }, [visible, isSSR])
  

  function onClose(){
    setVisible(false)
  }

  if(!visible) return null;
  
  return (
    <div className="c-alert-banner">
      <div className="c-alert-banner-inner inner">
        <div className="c-alert-banner-message" data-with-link={!!linkURL}>{ message }</div>
        { linkURL &&
        <a href={ linkURL } className="c-alert-banner-link" target="_blank">
          <span className="c-alert-banner-link-icon">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.80032 1.70024L10.0997 5.9996L-3.36604e-07 5.9996L-2.49154e-07 8.00022L10.0997 8.00022L5.80032 12.2996L7.2 13.7002L13.9003 6.99987L7.2 0.299555L5.80032 1.70024Z" fill="#F68B1F"/>
            </svg>
          </span>
          <span className="c-alert-banner-link-text">{ linkLabel }</span>
        </a>
        }
        <div className="c-alert-banner-close" onClick={ onClose }>
          <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 10L18.5 18.5" stroke="black" stroke-width="1.64516"/>
            <path d="M18.5 10L10 18.5" stroke="black" stroke-width="1.64516"/>
          </svg>
        </div>
      </div>
    </div>
  )
}

export const AlertBannerPropTypes = {
  message: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  linkURL: PropTypes.string,
  linkLabel: PropTypes.string,
}

AlertBanner.propTypes = AlertBannerPropTypes;

export default AlertBanner;


