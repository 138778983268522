import React from "react";
import BasicImage, { BasicImagePropTypes } from "../elements/basic-image";
import Button, { ButtonPropTypes } from "../elements/button";
import PropTypes from "prop-types";
import { HomeHeroBG } from "../helpers/icons";

const HomeHero = ({ title, image, text, link }) =>
  title && (
    <div className="m-home-hero">
      <div className="m-home-hero-inner inner">
        <div className="m-home-hero-image-container">
          {image && (
            <div className="m-home-hero-image e-image-container e-five-four-image-container">
              <BasicImage image={image} />
            </div>
          )}
        </div>
        <div className="m-home-hero-content">
          <h1 className="m-home-hero-title">{title}</h1>
          {text && <div className="m-home-hero-body body-regular">{text}</div>}
          {link && <Button {...link} includeIcon={true} type="primary" />}
        </div>
      </div>
      <HomeHeroBG className="m-home-hero-bg" />
    </div>
  );

HomeHero.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape(BasicImagePropTypes),
  text: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  link: PropTypes.shape(ButtonPropTypes),
};

export default HomeHero;
