import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../layouts";
import SEO from "../components/seo";
import HomeHero from "../modules/home-hero";
import linkHelper from "../helpers/link";
import ContentBlockLinkList from "../components/link-list";
import PageBuilder from "../modules/page-builder";
import AlertBanner from "../components/alert-banner";

export const query = graphql`
  query HomePageQuery {
    alertBanner: sanityAlertBanner {
      linkLabel
      linkURL
      message
      active
      _updatedAt
    }
    page: allSanityPageHome {
      nodes {
        title
        slug {
          current
        }
        ...homeBuilderFields
        seo {
          title
          keywords
          image {
            asset {
              url
            }
          }
          description
        }
        popularPages {
          title
          links {
            type
            buttonText
            page {
              ... on SanityPage {
                title
                slug {
                  current
                }
                path
              }
              ... on SanityPagePhotoComp {
                title
                slug {
                  current
                }
                path
              }
              ... on SanityPageNews {
                title
                slug {
                  current
                }
                path
              }
              ... on SanityPageAlumni {
                title
                slug {
                  current
                }
                path
              }
              ... on SanityPagePolicy {
                title
                slug {
                  current
                }
                path
              }
              ... on SanityPageContact {
                title
                slug {
                  current
                }
                path
              }
              ... on SanityPageEvents {
                title
                slug {
                  current
                }
                path
              }
              ... on SanityPolicyArea {
                title
                slug {
                  current
                }
                path
              }
              ... on SanityPolicySubmission {
                title
                slug {
                  current
                }
                path
              }
              ... on SanityEvent {
                title
                slug {
                  current
                }
                path
              }
              ... on SanityArticle {
                title
                slug {
                  current
                }
                path
              }
            }
            file {
              title
              file {
                asset {
                  url
                  title
                  extension
                  size
                }
              }
            }
            external {
              url
              title
            }
          }
        }
        image {
          asset {
            _id
            altText
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
          hotspot {
            height
            width
            x
            y
          }
        }
        heroTitle
        heroText
        heroButtonText
        heroButtonLink {
          type
          page {
            ... on SanityPage {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPagePhotoComp {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPageNews {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPageAlumni {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPagePolicy {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPageContact {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPageEvents {
              title
              slug {
                current
              }
              path
            }
          }
          file {
            title
            file {
              asset {
                url
                title
                extension
                size
              }
            }
          }
          external {
            url
            title
          }
        }
      }
    }
  }
`;

const Index = ({ data }) => {
  const alertBanner = data.alertBanner;
  const page = data.page.nodes[0];
  return (
    <Layout className="home">
      <SEO
        title={page.title}
        excerpt={page.excerpt}
        image={page.image}
        seo={page.seo}
      />
      {alertBanner && alertBanner.active && (
        <AlertBanner
          message={alertBanner.message}
          linkURL={alertBanner.linkURL}
          linkLabel={alertBanner.linkLabel}
          updatedAt={alertBanner._updatedAt}
        />
      )}
      <HomeHero
        title={page.heroTitle}
        image={page.image}
        text={page.heroText}
        link={{ ...linkHelper(page.heroButtonLink), text: page.heroButtonText }}
      />
      <ContentBlockLinkList {...page.popularPages} home={true} />
      <PageBuilder builder={page.pageBuilder} />
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object,
};

export default Index;
